/* reset css start */
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;600;700&display=swap");

// font-family: 'Josefin Sans', sans-serif;

$heading-font: "Josefin Sans", sans-serif;
$para-font: "Josefin Sans", sans-serif;

html {
  scroll-behavior: smooth;
}
body {
  font-family: $para-font;
  font-size: 18px;
  padding: 0;
  margin: 0;
  font-weight: 400;
  position: relative;
  background-color: $base-color;
  overflow-x: hidden;
}
img {
  max-width: 100%;
  height: auto;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}
button {
  cursor: pointer;
}
*:focus {
  outline: none;
}

button {
  border: none;
}
button:focus {
  outline: none;
}
span {
  display: inline-block;
}
a:hover {
  color: #ff0000;
}
/* reset css end */

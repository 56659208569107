/* button css start */
.cmn-btn {
  padding: 12px 30px;
  min-width: 230px;
  text-align: center;
  @include font(22px, weight("semi"), uppercase);
  @include border-radius(999px);
  @include gradient-two;
  box-shadow: 2.419px 9.703px 12.48px 0.52px rgba(232, 58, 153, 0.5);
  @include transition(all 0.3s);
  color: #ffffff;
  &:hover {
    color: #ffffff;
    background-size: 120%;
    @include transform(translateY(-3px));
  }
  @media (max-width: 1199px) {
    font-size: 18px;
    min-width: 200px;
  }
  @media (max-width: 991px) {
    font-size: 16px;
  }
  @media (max-width: 767px) {
    font-size: 14px;
  }
  &.style--two {
    background-image: linear-gradient(
      -45deg,
      rgb(193, 101, 221) 0%,
      rgb(92, 39, 254) 100%
    );
    box-shadow: 0px 17px 40px 0px rgba(0, 0, 0, 0.53);
    font-size: 22px;
    @media (max-width: 1199px) {
      font-size: 18px;
    }
    @media (max-width: 991px) {
      font-size: 16px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
  &.btn--sm {
    padding: 10px 22px;
    font-size: 14px;
  }
  &.btn--lg {
    padding: 12px 45px;
  }
}
.btn-grp {
  @include d-flex;
  justify-content: center;
  margin-left: -10px;
  margin-right: -10px;
  *[class*="btn"] {
    margin: 5px 10px;
    align-items: center;
    &.d-flex {
      padding: 8px 35px;
    }
  }
}
*[class*="btn"] {
  .btn-sm {
    padding: 6px 20px;
  }
}

.submit-btn {
  padding: 16px 50px;
  background-color: rgb(34, 10, 129);
  box-shadow: 0px 4px 30px 15px rgba(0, 162, 255, 0.49),
    inset 0px 0px 12px 6px #30a8db;
  position: relative;
  z-index: 9;
  text-transform: uppercase;
  color: #ffffff;
  @include border-radius(999px);
  @media (max-width: 575px) {
    font-size: 16px;
  }
}
/* button css end */

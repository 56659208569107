/* footer section css start */
.footer-section {
  position: relative;
  padding-top: 250px;
  @media (max-width: 991px) {
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: top;
    padding-top: 150px;
  }
}
.bg-shape--top {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  margin-top: -3px;
}
.subscribe-area {
  padding: 50px 50px;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 5px 8px 0px rgba(26, 15, 63, 0.08);
  @include border-radius(20px);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  position: relative;
  @media (max-width: 991px) {
    background-color: rgba(255, 255, 255, 0.5);
    padding: 30px;
  }
  .obj {
    position: absolute;
    top: -90px;
    left: 0;
    animation: customBounce 3s linear infinite;
    @media (max-width: 1199px) {
      top: -30px;
    }
    @media (max-width: 991px) {
      display: none;
    }
    img {
      @media (max-width: 1199px) {
        width: 80%;
      }
    }
  }
  .subscribe-content {
    width: 100%;
    .title {
      font-size: 36px;
      color: #1d2e82;
      @media (max-width: 767px) {
        font-size: 32px;
      }
      @media (max-width: 575px) {
        font-size: 24px;
      }
    }
    p {
      color: #151f7a;
    }
  }
  .subscribe-form {
    @include d-flex;
    margin-right: -45px;
    margin-top: 30px;
    @media (max-width: 575px) {
      margin-right: 0;
    }
    input {
      @include border-radius(999px);
      width: calc(100% - 160px);
      min-height: 60px;
      padding-left: 25px;
      padding-right: 40px;
      background-color: rgba(38, 13, 76, 0);
      box-shadow: inset 0 0 15px 7px #6b38f6;
      border: none;
      color: #363636;
      @media (max-width: 991px) {
        background-color: #f0d2ba;
      }
      @media (max-width: 575px) {
        width: 100%;
      }
    }
    button {
      background-image: linear-gradient(
        -45deg,
        rgb(193, 101, 221) 0%,
        rgb(92, 39, 254) 100%
      );
      box-shadow: 0px 17px 40px 0px rgba(0, 0, 0, 0.53);
      color: #ffffff;
      width: 160px;
      @include border-radius(999px);
      margin-left: -45px;
      @media (max-width: 575px) {
        margin-left: 0;
        min-height: 55px;
        width: 100%;
        margin-top: 15px;
      }
    }
  }
}
.footer-top {
  padding-top: 180px;
  padding-bottom: 110px;
  @media (max-width: 991px) {
    padding-top: 80px;
    padding-bottom: 60px;
  }
}
.footer-widget {
  &__title {
    margin-bottom: 25px;
  }
}
.short-links {
  li + li {
    margin-top: 15px;
  }
  li {
    a {
      font-size: 18px;
    }
  }
}
.footer-bottom {
  padding-bottom: 30px;
}
.copy-right-text {
  a {
    color: #ec038b;
  }
}

.social-links {
  @include d-flex;
  margin: -3px -7px;
  li {
    margin: 3px 7px;
    a {
      width: 44px;
      height: 44px;
      background-color: rgba(255, 255, 255, 0);
      border: 1px solid rgba(117, 54, 246, 0.52);
      text-align: center;
      line-height: 46px;
      @include border-radius(50%);
      @media (max-width: 575px) {
        width: 35px;
        height: 35px;
        line-height: 35px;
      }
      &:hover {
        background-image: linear-gradient(
          89deg,
          rgb(95, 22, 169) 0%,
          rgb(207, 21, 248) 100%
        );
        box-shadow: 0px 4px 9px 0px rgba(103, 22, 175, 0.71);
        color: #ffffff;
        border-color: #7216b6;
      }
    }
  }
}
/* footer section css end */

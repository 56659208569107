@mixin transform($property) {
  transform: $property;
}

@mixin border-radius($property) {
  border-radius: $property;
}

@mixin transition($property) {
  transition: $property;
}

@mixin flexWidth($property) {
  flex: 0 0 $property;
  max-width: $property;
}

@mixin d-flex {
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

@mixin object-fit {
  object-fit: cover;
  object-position: center;
}

@mixin animation($property) {
  animation: $property;
}

@mixin font($size, $weight, $transform) {
  font-size: $size;
  font-weight: $weight;
  text-transform: $transform;
}

// keyframes mixin
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin placeholder($value) {
  &::-webkit-input-placeholder {
    color: $value;
  }
  &::-moz-placeholder {
    color: $value;
  }
  &:-ms-input-placeholder {
    color: $value;
  }
  &:-moz-placeholder {
    color: $value;
  }
}

@mixin color($bg-color, $text-color) {
  background-color: $bg-color;
  color: $text-color;
}

@mixin gradient-one {
  background-image: linear-gradient(
    89deg,
    rgb(95, 22, 169) 0%,
    rgb(207, 21, 248) 100%
  );
}

@mixin gradient-two {
  background-image: linear-gradient(
    0deg,
    rgb(226, 144, 110) 0%,
    rgb(232, 58, 153) 100%
  );
}

@mixin gradient-three {
  background-image: linear-gradient(
    7deg,
    rgb(236, 19, 121) 0%,
    rgb(108, 0, 146) 100%
  );
}

@mixin text-gradient {
  background: #4380e4;
  background-clip: text;
  background: linear-gradient(
    0deg,
    rgb(226, 144, 110) 0%,
    rgb(232, 58, 153) 100%
  );
  -webkit-text-fill-color: transparent;
}

$heading-color: #ffffff;
$para-color: #c4cbf9;

$base-color: #000033;
$base-color-two: #ffa200;

// bg-color
$bg-one: #0f0232;
$bg-two: #02014a;

// border-color\
$border-color: rgba(#ffffff, 0.1);

// box-shadow
$shadow: 0 10px 20px 0 rgba(35, 72, 199, 0.25);
